














import { Watch, Component, Vue } from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import Feedback from '@/components/FeedbackPermission/index.vue';
import Footer from '@/components/Footer/Footer.vue';

@Component({
  components: {
    PageHeader,
    ContainerFluid,
    Feedback,
    Footer
  }
})
export default class FeedbackPermission extends Vue {
  private isMobile: boolean = this.$responsive.isMobile;

  mounted() {
    this.setBreadCrumbs();
  }

  goToDashboard() {
    this.$router.push({
      path: '/dashboard'
    });
  }

  @Watch('$responsive.isMobile', {
    immediate: true
  })
  onMobileChange() {
    this.isMobile = this.$responsive.isMobile;
  }

  setBreadCrumbs() {
    const { nameBreadcrumb } = this.$route.params;

    this.$breadcrumb.set([{ title: nameBreadcrumb, to: null }]);
  }
}
